import React, { useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Box,
  Button,
  Card,
  Icon,
  Spinner,
  List,
  useToast,
  Input,
  Text,
  Label,
  Select,
  Title,
} from '@nimbus-ds/components';
import { CogIcon, LockOpenIcon, UserIcon } from '@nimbus-ds/icons';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useHistory } from 'react-router-dom';
import { Table } from '@nimbus-ds/components';

const headers = [
  'Id',
  'Nombre Tienda',
  'Origen producto',
  'Tipo oferta',
  'Registro marca',
  'Url',
  'Nombre',
  'Teléfono',
  'Email',
  'Seller Tag',
  'Acciones',
];

import axiosInstance from '../../axios';

const SetupPage: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [isVerifing, setIsVerifing] = useState(false);
  const [isVinculate, setIsVinculate] = useState(false);
  const [showMessageVinculateAccount, setShowMessageVinculateAccount] =
    useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({
    usuario_api: '',
    access_key: '',
  });
  const [pendingSellers, setPendingSellers] = useState<Seller[]>([]);
  const [approvedSellers, setApprovedSellers] = useState<Seller[]>([]);

  interface Seller {
    store_id: string;
    nombre_tienda: string;
    origen_producto: string;
    tipo_oferta: string;
    registro_marca: string;
    nombre: string;
    telefono: string;
    email: string;
    url_tienda: string;
    url: string;
    seller_tag: string;
    status: string;
  }

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: 'Volver al inicio' });
  }, []);

  useEffect(() => {
    //checkVinculateAccount();
    getListOfPendingSellers();
    getListOfSellers();
  }, []);

  const handleGoToDashboard = async () => {
    push('/');
  };

  const handleGoToConfig = async () => {
    push('/config');
  };

  const handleChange = (e: any) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const getDashboard = async () => {
    try {
      const r = await axiosInstance.get('/dashboard');
      console.log(r);
    } catch (e) {
      console.error(e);
    }
    return;
  };
  getDashboard();

  const getListOfPendingSellers = async () => {
    console.log('obteniendo sellers pendientes...');
    try {
      const response = await axiosInstance.get('/pending_sellers_list');
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setPendingSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const getListOfSellers = async () => {
    console.log('obteniendo sellers aprobados...');
    try {
      const response = await axiosInstance.get('/approved_sellers_list');
      console.log(response);
      if (response.data.status == true) {
        console.log(response.data.sellers);
        setApprovedSellers(response.data.sellers);
      }
    } catch (e) {
      console.error(e);
    }
    return;
  };

  const handleSellerStatusChange = (e: any) => {
    console.log(e.target.value, ' - ', e.target.key, ' - ', e.target.label);
    console.log(e.target.id);
    console.log(e.key);
  };

  const approveSeller = async (store_id: any) => {
    console.log('approve seller store id : ' + store_id);
    await axiosInstance
      .post('/approve_seller', {
        store_id: store_id,
      })
      .then((response) => {
        console.log(response);
        if (response.data.status == true) {
          const seller = pendingSellers.find((s) => s.store_id == store_id);
          console.log('seller found to remove ? ', seller);
          setPendingSellers(
            pendingSellers.filter((seller) => {
              console.log(
                'seller store id dif?? ' + (seller.store_id !== store_id),
              );
              return seller.store_id !== store_id;
            }),
          );
          if (seller) setApprovedSellers([...approvedSellers, seller]);

          addToast({
            id: 'approve-seller-success',
            type: 'success',
            text: 'Seller aprobado!',
            duration: 4000,
          });
        }
      })
      .catch((e) => {
        addToast({
          id: 'approve-seller-fail',
          type: 'danger',
          text:
            e.response.data?.message ?? 'Error al tratar de aprobar el seller',
          duration: 4000,
        });
        console.log(e);
      });
    return;
  };

  const viewSeller = async (store_id: any) => {
    console.log('view seller store id : ' + store_id);
    return;
  };

  /*
  const checkVinculateAccount = async () => {
    console.log('checkeand vinculacion de cuenta...');
    try {
      const response = await axiosInstance.get('/check_status_account');
      console.log(response);
      if (response.data.status == true) {
        console.log('CUENTA YA VINCULADA');
        setIsVerifing(false);
        setIsVinculate(true);
      }
    } catch (e) {
      console.error(e);
      setIsVerifing(false);
      setIsVinculate(false);
    }
    return;
  };
  */

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(email)) {
      setError(true);
      return false;
    } else {
      setError(false);
      return true;
    }
  };

  const handleVinculateAccount = async () => {
    setLoading(true);
    const isFormCorrect = validateEmail(user.usuario_api);
    if (isFormCorrect) {
      setShowMessageVinculateAccount(true);
      try {
        const response = await axiosInstance.post('/register', {
          usuario_api: user.usuario_api,
          siigo_token: user.access_key,
        });
        if (response.data.status == true) {
          addToast({
            id: 'login-toast',
            type: 'success',
            text: 'Vinculación de cuenta exitosa! 😀',
            duration: 4000,
          });
          setIsVerifing(false);
          setIsVinculate(true);
          setShowMessageVinculateAccount(false);
        } else {
          setShowMessageVinculateAccount(false);
          addToast({
            id: 'login-toast',
            type: 'danger',
            text: 'Error Vinculando cuenta. Verifique sus credenciales.',
            duration: 4000,
          });
        }
      } catch (error) {
        console.log(error);
        setError(true);
        setShowMessageVinculateAccount(false);
      }
    } else {
      addToast({
        id: 'email-message-toast',
        type: 'danger',
        text: 'Verifique su email',
        duration: 4000,
      });
    }
    setLoading(false);
    return;
  };

  if (isVerifing)
    return (
      <div>
        <label>Verificando vinculación...</label>
      </div>
    );

  return (
    <Page maxWidth="1000px" marginTop="18">
      {!isVerifing && isVinculate ? (
        <>
          <Page.Header
            alignItems="center"
            title="Tu cuenta ya está vinculada y activa"
          />
          <Box display="flex" gap="4" justifyContent="center">
            <Button appearance="primary" onClick={handleGoToConfig}>
              <Icon source={<CogIcon />} color="currentColor" />
              Ir a configuración
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Page.Header alignItems="center" title="Sellers" />
          <Page.Body>
            <Layout columns="1">
              <Layout.Section>
                <Box display="flex" flexDirection="column" gap="4" padding="4">
                  <Title textAlign="center" as="h3">
                    Lista de sellers pendientes
                  </Title>
                </Box>
                <Table>
                  <Table.Head>
                    {headers.map((header) => (
                      <Table.Cell key={header}>{header}</Table.Cell>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {pendingSellers.map((seller) => (
                      <Table.Row key={seller.store_id}>
                        <Table.Cell>{seller.store_id}</Table.Cell>
                        <Table.Cell>{seller.nombre_tienda}</Table.Cell>
                        <Table.Cell>{seller.origen_producto}</Table.Cell>
                        <Table.Cell>{seller.tipo_oferta}</Table.Cell>
                        <Table.Cell>{seller.registro_marca}</Table.Cell>
                        <Table.Cell>
                          {seller.url ?? seller.url_tienda}
                        </Table.Cell>
                        <Table.Cell>{seller.nombre}</Table.Cell>
                        <Table.Cell>{seller.telefono}</Table.Cell>
                        <Table.Cell>{seller.email}</Table.Cell>
                        <Table.Cell>{seller.seller_tag}</Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => approveSeller(seller.store_id)}
                            appearance="primary"
                          >
                            Aprobar
                          </Button>
                          {/*
                            <Select
                              appearance="neutral"
                              id={seller.store_id}
                              name="Name"
                              onChange={(event) => {
                                handleSellerStatusChange(event);
                              }}
                            >
                              <Select.Option
                                value="pendiente"
                                label="pendiente"
                                selected={seller.status == 'pendiente'}
                              ></Select.Option>
                              <Select.Option
                                value="aprobado"
                                label="aprobado"
                                selected={seller.status == 'aprobado'}
                              ></Select.Option>
                              <Select.Option
                                value="inactivo"
                                label="inactivo"
                                selected={seller.status == 'inactivo'}
                              ></Select.Option>
                            </Select>
                            */}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Box display="flex" flexDirection="column" gap="4" padding="4">
                  <Title textAlign="center" as="h3">
                    Lista de sellers aprobados
                  </Title>
                </Box>
                <Table>
                  <Table.Head>
                    {headers.map((header) => (
                      <Table.Cell key={header}>{header}</Table.Cell>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {approvedSellers.map((seller) => (
                      <Table.Row key={seller.store_id}>
                        <Table.Cell>{seller.store_id}</Table.Cell>
                        <Table.Cell>{seller.nombre_tienda}</Table.Cell>
                        <Table.Cell>{seller.origen_producto}</Table.Cell>
                        <Table.Cell>{seller.tipo_oferta}</Table.Cell>
                        <Table.Cell>{seller.registro_marca}</Table.Cell>
                        <Table.Cell>
                          {seller.url ?? seller.url_tienda}
                        </Table.Cell>
                        <Table.Cell>{seller.nombre}</Table.Cell>
                        <Table.Cell>{seller.telefono}</Table.Cell>
                        <Table.Cell>{seller.email}</Table.Cell>
                        <Table.Cell>{seller.seller_tag}</Table.Cell>
                        <Table.Cell>
                          <Button
                            onClick={() => viewSeller(seller.store_id)}
                            appearance="primary"
                          >
                            Ver
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Layout.Section>
            </Layout>
          </Page.Body>
        </>
      )}
    </Page>
  );
};

export default SetupPage;
